import React from "react"
import {HeadFC} from "gatsby"
import {useStaticQuery, graphql} from "gatsby"

import Layout from "../components/layout"
import parse from "html-react-parser"
import HomePGSNews from "../components/home/pgs-news"
import GeneralNews from "../components/home/general-news"

import "../styles/global.scss"
import {SEO} from "../components/seo"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      datoCmsPage(menuName: {eq: "Home"}) {
        title
        body
      }
    }
  `)
  return (
    <Layout currentLocation="index">
      <div className="content">
        <div className="welcome">
          <img src="/images/home/books-pgs13.png" alt=""></img>
          <div className="introduction">
            <h1>
              <span className="title">Publicatiereeks</span>
              <span className="subtitle">Gevaarlijke Stoffen</span>
            </h1>
            {parse(data.datoCmsPage.body)}
          </div>
        </div>
        <HomePGSNews />
        <GeneralNews />
      </div>
    </Layout>
  )
}

export default IndexPage

export const Head: HeadFC = () => <SEO />
