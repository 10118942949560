import React from "react"
import {useStaticQuery, graphql, Link} from "gatsby"
import slugify from "../../functions/slugify"
import {NewsNode} from "../../interfaces/news"

const GeneralNews = () => {
  const formattedDate = (date: string) => {
    const options: object = {day: "2-digit", month: "short", year: "numeric"}
    return new Date(date).toLocaleDateString("en-NL", options)
  }
  const data = useStaticQuery(graphql`
    query {
      allDatoCmsNews(
        filter: {publicationType: {eq: "algemeen"}}
        sort: {fields: date, order: DESC}
        limit: 3
      ) {
        nodes {
          publicationType
          title
          date
        }
      }
    }
  `)
  return (
    <div className="homenews other">
      <Link to="/overig-nieuws" className="news-link">
        <h2>Overig Nieuws</h2>
      </Link>
      {data.allDatoCmsNews.nodes.map((news: NewsNode, index: number) => (
        <div className="news-item" key={index}>
          <div className="date">
            <div className="day">
              {formattedDate(news.date).slice(0, 2)}&nbsp;
            </div>
            <div className="month">{formattedDate(news.date).slice(2, 6)}</div>
          </div>
          <Link to={`/nieuws/${slugify(news.title)}`} className="description">
            <span className="title">{news.title}</span>
          </Link>
        </div>
      ))}
    </div>
  )
}

export default GeneralNews
