import React from "react"
import {useStaticQuery, graphql, Link} from "gatsby"
import {NewsNode} from "../../interfaces/news"
import slugify from "../../functions/slugify"
import parse from "html-react-parser"

const HomePGSNews = () => {
  const formattedDate = (date: string) => {
    const options: object = {day: "2-digit", month: "short", year: "numeric"}
    return new Date(date).toLocaleDateString("en-NL", options)
  }
  const data = useStaticQuery(graphql`
    query {
      allDatoCmsNews(
        filter: {publicationType: {eq: "publicatie"}}
        sort: {fields: date, order: DESC}
        limit: 3
      ) {
        nodes {
          publicationType
          title
          introduction
          date
        }
      }
    }
  `)
  return (
    <div className="homenews pgs">
      <Link to="/pgs-nieuws" className="news-link">
        <h2>PGS nieuws</h2>
      </Link>{" "}
      {data.allDatoCmsNews.nodes.map((news: NewsNode, index: number) => (
        <div className="news-item" key={index}>
          <a href={`/nieuws/${slugify(news.title)}`} className="description">
            <div className="date">
              <div className="day">{formattedDate(news.date).slice(0, 2)}</div>
              <div className="month">
                {formattedDate(news.date).slice(2, 6)}
              </div>
            </div>
            <div className="content">
              <h4>{news.title}</h4>
              <p>{parse(news.introduction)}</p>
            </div>
          </a>
        </div>
      ))}
    </div>
  )
}

export default HomePGSNews
